import React from 'react'
import { graphql, Link } from "gatsby";
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import bodykomImg from "../img/bodykom.png";
import kallelse from "../docs/kallelse-arsstamma-2024-06-27.pdf";
import bemyndigande from "../docs/bemyndigande-2024.pdf";
import fullmakt from "../docs/fullmaktsformular-2024.pdf";
import arsredovisning from "../docs/kiwok-arsredovisning.pdf";
import kiwokWhite from "../img/Kiwok_Logo_White.png";
import bolagsordning from "../docs/bolagsordning-2023.pdf"
import bjornerik from "../docs/bjorn-erik-erlandsson-kthprofil.pdf"
//import styles from '../components/base.css'

import pdfIcon from "../img/PDF_Icon.png";

class arsstamma extends React.Component{
    render(){
        const siteTitle = ('Kiwok - Årsstämma')

        return (
            <Layout location={this.props.location} >
            <div style={{ background: '#edeef2' }}>
              <Helmet title={siteTitle} />
              
              <div className="arsstamma">
                <div className="container">
                <div className="intro-text-half-page">
                    <h1 className="hIntro" style={{ color: '#141414' }}>Årsstämma i Kiwok Nordic AB (publ).</h1>
                    <p className="pIntro" style={{ color: '#141414' }}>Torsdagen den 27 juni 2024 i Stockholm</p>
                </div>
                <div className="intro-text-half-page"> 
                    <div className="img-placeholder"></div>
                </div>
                </div>
            </div>

            <div className="container-full-width" style={{ background: '#266de1' }}>
                <div className="container" >
                    <div className="intro-text-half-page"> 
                        <img style={{ maxWidth: '60%', paddingLeft: '10%'}} className ="half-img" src={kiwokWhite}></img>
                    </div>
                    <div className="intro-text-half-page">
                        <p style={{ color: '#fff' }}>Kiwok är ett svenskt medicintekniskt företag grundat 2003. 
                        Tillsammans med akademin utvecklar vi nästa generation lösningar för trendmonitorering av patientens vitala hälsoparametrar. 
                        BodyKom, som vid lanseringen enligt Gartner var den första mobilt uppkopplade hälsolösningen, är en global vårdtjänst för kontinuerlig distnansmonitorering
                         av patienters hälsoutveckling under längre tid. Bodykom leder till tidigare upptäckt och diagnos samt säkerställer uppföljning och återhämtning. 
                         Kiwoks lösningar ger en hållbar vård och personcentrerade vårdprocess.</p>
                    </div>
                </div>
            </div>

            <div  id="emission" className="container-full-width" style={{ background: '#adcaea' }}>
                <div className="container" >
                    <h3 className="boldedStart">Årsstämma i Kiwok Nordic (publ)</h3>
                    <p style={{ color: '#000' }}>Aktieägarna i Kiwok Nordic AB (publ), org. nr. 556665-3068, kallas härmed till årsstämma torsdagen den 27 juni 2024 klockan 15:00 
                      på The Park Forskaren, Hagaplan 1, Stockholm. Aktieägare som önskar delta i årsstämman skall dels vara införd i den av Euroclear Sweden AB förda aktieboken 
                      tisdagen den 18 juni 2024, dels göra anmälan till bolaget senast kl. 16:00 måndagen den 24 juni 2024 under adress Kiwok Nordic AB (publ), Box 45017, SE-104 30 
                      Stockholm eller via e-post info@kiwok.se. Vid anmälan skall uppges namn, person-/organisationsnummer, adress, telefonnummer och aktieinnehav. I det fall 
                      aktieägaren avser att låta sig företrädas genom ombud bör behörighetshandlingar, såsom exempelvis fullmakter och registreringsbevis bifogas anmälan, dessa 
                      måste vidare vara tillgängliga vid stämman. Bolaget tillhandahåller fullmaktsformulär på begäran och detta kommer också att finnas på bolagets webbplats, 
                      www.kiwok.se, senast 3 veckor före stämman. Aktieägare som har sina aktier förvaltarregistrerade måste, för att äga rätt att delta i årsstämman, tillfälligt 
                      inregistrera aktierna i eget namn. Aktieägare som önskar sådan omregistrering måste underrätta sin förvaltare om detta i god tid före tisdagen den 18 juni 2024, då sådan omregistrering skall vara verkställd.</p>
                </div>
            </div>
            <div className="container-full-width" style={{ background: '#adcaea', padding: '0' }}>
            <div className="wrapper1">
                <div className="wrapper-child-emission">
                <a href={arsredovisning} target="_blank"><img
                    style={{width: '90px', height: 'auto'}}
                    className="about-img"
                    src={pdfIcon}
                    alt="Bodykom"
                  ></img></a>
                  <p style={{ textAlign: 'center' }}>Årsredovisning</p>
                </div>
                <div className="wrapper-child-emission">
                <a href={kallelse} target="_blank"><img
                    style={{width: '90px', height: 'auto'}}
                    className="about-img"
                    src={pdfIcon}
                    alt="Bodykom"
                  ></img></a>
                  <p style={{ textAlign: 'center' }}>Kallelse till årsstämma</p>
                </div>
                <div className="wrapper-child-emission">
                <a href={bemyndigande} target="_blank"><img
                    style={{width: '90px', height: 'auto'}}
                    className="about-img"
                    src={pdfIcon}
                    alt="Bodykom"
                  ></img></a>
                  <p style={{ textAlign: 'center' }}>Bemyndigande</p>
                </div>
                <div className="wrapper-child-emission">
                <a href={fullmakt} target="_blank"><img
                    style={{width: '90px', height: 'auto'}}
                    className="about-img"
                    src={pdfIcon}
                    alt="Bodykom"
                  ></img></a>
                  <p style={{ textAlign: 'center' }}>Fullmaktsformulär</p>
                </div>
                {/*<div className="wrapper-child-emission">
                <a href={bolagsordning} target="_blank"><img
                    style={{width: '90px', height: 'auto'}}
                    className="about-img"
                    src={pdfIcon}
                    alt="Bolagsorning PDF"
                  ></img></a>
                  <p style={{ textAlign: 'center' }}>Förslag till bolagsordning</p>
                </div>
                <div className="wrapper-child-emission">
                <a href={bjornerik} target="_blank"><img
                    style={{width: '90px', height: 'auto'}}
                    className="about-img"
                    src={pdfIcon}
                    alt="kth-profil"
                  ></img></a>
                  <p style={{ textAlign: 'center' }}>Björn-Erik Erlandsson</p>
                </div>*/}
            </div>
            </div>
            </div>
          </Layout>
        )
    }
}

export default arsstamma